import React from 'react';

function pluralizeTimeToRead(time: number): string {
  if (time === 1) {
    return `Čtení na ${time} minutu`;
  } else if (time <= 4) {
    return `Čtení na ${time} minuty`;
  } else {
    return `Čtení na ${time} minut`;
  }
}

const TimeToRead = ({ time }: { time: number }) => (
  <>{pluralizeTimeToRead(time)}</>
);

export default TimeToRead;
