import React, { useCallback, useEffect, useState } from 'react';

export interface MailToProps extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'onClick'> {
  email: string;
}

export const MailTo = ({ email, ...rest }: MailToProps) => {
  const [href, setHref] = useState('javascript:;');

  useEffect(() => {
    setHref('javascript:;');
  }, [email]);

  const mailTo = useCallback(() => {
    setHref(`mailto:${email}`);
  }, [setHref, email]);

  return (
    <a {...rest} href={href} onClick={mailTo}/>
  );
};
