import { graphql, useStaticQuery } from 'gatsby';

export function useIntroduction(): string {
  const { introduction } = useStaticQuery(graphql`
    {
      introduction: markdownRemark(
        fileAbsolutePath: { glob: "**/content/introduction.md" }
      ) {
        html
      }
    }
  `);

  return introduction.html;
}
