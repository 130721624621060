import { faBook, faEye } from '@fortawesome/free-solid-svg-icons';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import { FixedObject, FluidObject } from 'gatsby-image';
import React, { useCallback } from 'react';
import styled, { css, keyframes } from 'styled-components';

import {
  ButtonDivider,
  ButtonLikeAnchor,
  ButtonLikeLink,
  ImageButtonLikeAnchor,
} from '../components/button';
import HeroImage from '../components/hero-image';
import Layout from '../components/layout';
import { MailTo } from '../components/mail-to';
import { Markdown } from '../components/markdown';
import SEO from '../components/seo';
import TimeToRead from '../components/time-to-read';
import { useBlogPosts } from '../hooks/use-blog-posts';
import { useFragmentList } from '../hooks/use-fragments';
import { useIntroduction } from '../hooks/use-introduction';

const ArticleGrid = styled.main`
  --gap-color: var(--text-color);
  --gap: 1px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-gap: var(--gap);

  min-height: 100vh;
  padding: var(--gap) 0;
  margin-bottom: -var(--gap);

  background-color: var(--gap-color);

  @media screen and (max-width: calc(1000rem / 16)) {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }
`;

const fadeInAnimation = keyframes`
  0% {
    transform: translateY(-0.5rem);
    opacity: 0;
  }

  80% {
    transform: none;
  }

  100% {
    opacity: 1;
  }
`;

const TileBase = css`
  --tile-color: var(--background-color);
  --tile-padding: 2rem 1rem;
  --tile-reveal-duration: 0.25s;
  --tile-stagger-duration: 0.065s;
  --tile-stagger: ${({ index }: { index?: number }) => index ?? 0};

  @media screen and (min-width: 500px) {
    --tile-padding: 5rem;
  }

  display: grid;
  grid-template:
    ' title title ' auto
    ' link  time  ' auto / 1fr auto;

  @media screen and (max-width: calc(1000rem / 16)) {
    grid-template:
      ' title ' auto
      ' time  ' auto
      ' link  ' auto / 1fr;
  }

  padding: var(--tile-padding);

  &:last-of-type:nth-child(2n + 1) {
    grid-column: 1 / -1;
  }

  background-color: var(--tile-color);

  font-family: classica-pro, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.125rem;

  transition-property: color, background;
  transition-timing-function: ease-out;
  transition-duration: 0.15s;

  & > * {
    animation: ${fadeInAnimation} var(--tile-reveal-duration) ease-out 1;
    animation-fill-mode: both;
    animation-delay: calc(
      var(--tile-stagger-duration) * var(--tile-stagger, 0)
    );
  }
`;

const TileContainer = styled.article`
  ${TileBase};

  cursor: pointer;

  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    background-color: var(--highlight-background-color);
    color: var(--highlight-text-color);
  }
`;

const TileHeader = styled.h1`
  font-family: jaf-lapture-caption, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.5rem;

  @media screen and (min-width: 500px) {
    font-size: 2.75rem;
  }

  grid-area: title;
  align-self: end;
  justify-self: start;

  max-width: calc(480rem / 16);

  padding-bottom: 1rem;
`;

const TileLink = styled(Link)`
  grid-area: link;
  justify-self: start;
  align-self: end;

  &:visited {
    color: inherit;
  }
`;

const TileReadTime = styled.span`
  grid-area: time;
  justify-self: end;
  align-self: end;

  @media screen and (max-width: calc(1000rem / 16)) {
    justify-self: start;
    padding-bottom: 0.5rem;
  }
`;

interface TileProps {
  article?: any;
  index?: number;
}
const Tile = ({ article, index }: TileProps) => (
  <TileContainer onClick={() => navigate(article.path)} index={index}>
    {article ? (
      <>
        <TileHeader>{article.title}</TileHeader>
        <TileLink to={article.path}>Precist prispevek &#8594;</TileLink>
        <TileReadTime>
          <TimeToRead time={article.timeToRead}></TimeToRead>
        </TileReadTime>
      </>
    ) : null}
  </TileContainer>
);

const ContentSection = styled.section`
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  max-width: var(--content-size);
  padding-left: var(--content-padding);
  padding-right: var(--content-padding);
`;

const MainSection = styled(ContentSection)`
  @media screen and (min-width: 680px) {
    padding-top: 140px;
  }
  padding-top: 90px;
  padding-bottom: 60px;
`;

const FragmentsMarkdown = styled(Markdown)`
  display: flex;
  flex-wrap: wrap;

  h1,
  h2 {
    flex: 1 1 100%;
  }

  ul {
    flex: 1 0 50%;

    @media screen and (min-width: 700px) {
      flex-basis: 25%;
    }
  }
`;

const Divider = styled.hr`
  display: block;
  height: 1px;
  width: 100%;
  background-color: currentColor;
  margin: 29px 0;
  border: none;

  @media screen and (min-width: 500px) {
    margin: 59px 0;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: var(--content-padding);
  padding-right: var(--content-padding);
  place-content: center;
  gap: 10px;
`;

const FooterContainer = styled.footer`
  display: grid;
  grid-template: ' message-me logo back-to-top' auto / 1fr auto 1fr;
  place-items: center;
  padding-top: 36px;
  padding-bottom: 80px;
  padding-left: var(--content-padding);
  padding-right: var(--content-padding);
  margin: 0 auto;
`;

const footerButton = css`
  text-decoration: underline;
`;

const FooterMessageMe = styled(MailTo)`
  ${footerButton};
  place-self: center start;
`;

const FooterBackToTop = styled.a`
  ${footerButton};
  place-self: center end;
`;

const FooterSymbol = styled.img`
  filter: invert(var(--invert));
`;

const Footer = () => {
  const scrollToTop = useCallback(() => {
    document.body.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <FooterContainer>
      <FooterMessageMe email="hello@petrkriz.com">Napište mi</FooterMessageMe>
      <FooterSymbol src="/symbol-96x96.png" alt="" />
      <FooterBackToTop onClick={scrollToTop}>Nahoru</FooterBackToTop>
    </FooterContainer>
  );
};

const IndexPage = () => {
  const articles = useBlogPosts();

  const introduction = useIntroduction();
  const fragmentList = useFragmentList();

  const staticData = useStaticQuery(graphql`
    {
      dobrovsky: file(relativePath: { eq: "dobrovsky.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      kosmas: file(relativePath: { eq: "kosmas.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const dobrovsky = staticData.dobrovsky.childImageSharp.fixed as FixedObject;
  const kosmas = staticData.kosmas.childImageSharp.fixed as FixedObject;

  return (
    <Layout>
      <SEO title="Home" />
      <MainSection>
        <Markdown dangerouslySetInnerHTML={{ __html: introduction }} />
      </MainSection>
      <HeroImage />
      <ContentSection>
        <ActionButtons>
          <ButtonLikeAnchor
            inverted={true}
            icon={faBook}
            href="mailto:hello@petrkriz.com?Subject=Objednavka"
            target="_blank"
          >
            Koupit knihu
          </ButtonLikeAnchor>
          <ButtonLikeLink icon={faEye} to="/ukazka-z-knihy-belario">
            Přečíst ukázku
          </ButtonLikeLink>
          <ButtonDivider />
          <ImageButtonLikeAnchor
            href="https://www.kosmas.cz/knihy/286501/relikty-minulosti-podzimni-boure/"
            image={kosmas}
            title="Koupit na kosmas.cz"
          >
            Koupit na kosmas.cz
          </ImageButtonLikeAnchor>
          <ImageButtonLikeAnchor
            href="https://www.knihydobrovsky.cz/kniha/relikty-minulosti-podzimni-boure-314675898"
            image={dobrovsky}
            title="Koupit na knihydobrovsky.cz"
          >
            Koupit na knihydobrovsky.cz
          </ImageButtonLikeAnchor>
        </ActionButtons>
        <Divider />
        <FragmentsMarkdown dangerouslySetInnerHTML={{ __html: fragmentList }} />
      </ContentSection>
      <ArticleGrid>
        {articles.map((article, index) => (
          <Tile key={article.path} article={article} index={index + 1}></Tile>
        ))}
      </ArticleGrid>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
