import { graphql, Link, navigate, useStaticQuery } from 'gatsby';

export interface BlogPost {
  readonly title: string;
  readonly path: string;
  readonly timeToRead: number;
}

export function useBlogPosts(): readonly BlogPost[] {
  const { posts } = useStaticQuery(graphql`
    query BlogPosts {
      posts: allMarkdownRemark(
        sort: { fields: frontmatter___date, order: DESC }
        filter: { fileAbsolutePath: { glob: "**/blog/**" } }
      ) {
        edges {
          node {
            frontmatter {
              title
              path
            }
            timeToRead
          }
        }
      }
    }
  `);

  return posts?.edges?.map((e) => ({
    title: e?.node?.frontmatter?.title,
    path: e?.node?.frontmatter?.path,
    timeToRead: e?.node?.timeToRead
  }));
}
