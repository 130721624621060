import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import { FixedObject } from 'gatsby-image';
import React from 'react';
import styled, { css } from 'styled-components';

const ButtonIcon = styled(FontAwesomeIcon)`
  grid-area: icon;
  margin-right: 0.5em;
`;

export const ButtonStyle = css`
  --button-border-size: 2px;
  --button-border-radius: 4px;
  --button-height: 50px;
  --button-width: 180px;

  display: inline-block;
  height: var(--button-height);
  min-width: var(--button-width);
  line-height: calc(var(--button-height) - (2 * var(--button-border-size)));
  padding: 0 10px;
  border: var(--button-border-size) solid var(--text-color);
  border-radius: var(--button-border-radius);
  cursor: pointer;
  font-family: classica-pro, serif;
  font-size: 18px;
  text-align: center;
  text-decoration: none;

  ${({ inverted = false }: { inverted?: boolean }) =>
    inverted
      ? css`
          color: var(--highlight-text-color);
          background: var(--highlight-background-color);
        `
      : css`
          background: var(--background-color);
          color: var(--text-color);
        `}

  &:not(:disabled) {
    &:hover,
    &:focus {
      text-decoration: underline;
    }

    ${({ inverted = false }: { inverted?: boolean }) =>
      inverted
        ? css`
            &:active,
            &:focus {
              background: var(--background-color);
              color: var(--text-color);
            }
          `
        : css`
            &:active,
            &:focus {
              color: var(--highlight-text-color);
              background: var(--highlight-background-color);
            }
          `}
  }

  &:disabled {
    opacity: 0.5;
  }
`;

function buttonAttrs({
  icon,
  children,
}: {
  icon?: IconDefinition;
  children: React.ReactChildren;
}) {
  return {
    children: icon ? (
      <>
        <ButtonIcon icon={icon} />
        {children}
      </>
    ) : (
      children
    ),
  };
}

export const Button = styled.button.attrs(buttonAttrs)`
  ${ButtonStyle}
`;

export const ButtonLikeLink = styled(Link).attrs(buttonAttrs)`
  ${ButtonStyle}
`;

export const ButtonLikeAnchor = styled.a.attrs(buttonAttrs)`
  ${ButtonStyle}
`;

export const ImageButtonLikeAnchor = styled.a.attrs(buttonAttrs)`
  color: transparent;
  user-select: none;

  ${(props: { image: FixedObject }) => css`
    background: url(${props.image.src});
    width: ${props.image.width / 2}px;
    height: ${props.image.height / 2}px;
  `}

  background-size: contain;
  background-repeat: no-repeat;
`;

export const ButtonDivider = styled.div`
  width: 2px;
  margin: 5px 0;
  background: var(--text-color);

  @media screen and (max-width: 1100px) {
    width: calc(100% - 10px);
    height: 1px;
    margin: 0 5px;
    background: none;
  }

  :after {
    content: '';
    display: block;
    height: 100%;
    margin: 0 auto;
    width: 100%;
    max-width: calc(180px * 2 + 10px);
    background: var(--text-color);
  }
`;
