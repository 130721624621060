import Img, { FluidObject } from 'gatsby-image';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import styled from 'styled-components';

const Hero = styled(Img)`
  margin: 0 auto;
  max-width: calc(1280rem / 16);
  border-radius: 0.5rem;
  overflow: hidden;
`;

const HeroImageContainer = styled.div`
  padding: 0 var(--content-padding) 30px var(--content-padding);
`;

const HeroImage = () => {
  const data = useStaticQuery(graphql`
    {
      heroImage: file(relativePath: { eq: "Hero-image.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const hero = data.heroImage.childImageSharp.fluid as FluidObject;

  return (
    <HeroImageContainer>
      <Hero fluid={hero} />
    </HeroImageContainer>
  );
};

export default HeroImage;
